import { merge } from "lodash";
import fulfillmentModuleActions from "./fulfillment/actions";
import vendorModuleActions from "./vendorModule/actions";
import buyerModuleActions from "./buyerModule/actions";
import cartModuleActions from "./cartModule/actions";
import purchaseModuleActions from "./purchaseModule/actions";
import ordersModuleActions from "./ordersModule/actions";

export default merge(
  fulfillmentModuleActions,
  vendorModuleActions,
  buyerModuleActions,
  cartModuleActions,
  purchaseModuleActions,
  ordersModuleActions,
);
