import { get, isEmpty, some } from "lodash";
import USER_TYPE_CONSTANTS from "@/constants/user-type-constants";

const setCurrentLoggedInInfo = (state, payload) => {
  state.exp = payload.exp;
  state.access_token = payload.access_token;
  state.currentLoggedInInfo = payload.decodedToken;
  if (isEmpty(payload.decodedToken)) {
    state.currentUserPermissions = {};
  } else {
    const { sim, superAdminPermissionDetails } = payload.decodedToken;
    const locationPermissions = get(sim.details, "locationPermissions", []);
    const orgPermissions = get(sim.details, "orgPermissions", []);
    const userType = get(sim.user, "userType", "");
    const isSimpatraAdmin = get(sim, "simpatraAdmin", false);
    const isOrgAdmin = get(sim.details, "isAdmin", false);
    const isOrgOwner = get(sim.details, "isOwner", false);
    const isLocationAdmin =
      some(locationPermissions, permission => {
        return permission.name === "LocAdmin" && permission.value === 2;
      }) || isSimpatraAdmin;
    const isLocationProfileAdmin = some(locationPermissions, permission => {
      return permission.name === "LocProfile" && permission.value === 2;
    });
    const isLocationRegistrationAdmin = some(locationPermissions, permission => {
      return permission.name === "LocRegistration" && permission.value === 2;
    });
    const isLocationPaymentAdmin = some(locationPermissions, permission => {
      return permission.name === "LocPayment" && permission.value === 2;
    });
    const isLocationLicenseAdmin = some(locationPermissions, permission => {
      return permission.name === "LocLicenses" && permission.value === 2;
    });

    const isSchedulingAdmin = some(locationPermissions, permission => {
      return permission.name === "LocScheduling" && permission.value === 2;
    });
    const isPatientAdmin = some(locationPermissions, permission => {
      return permission.name === "LocPatient" && permission.value === 2;
    });
    const isOrgDirectoryAdmin = some(orgPermissions, permission => {
      return permission.name === "OrgDirectory" && permission.value === 2;
    });
    const isOrgApproverAdmin = some(orgPermissions, permission => {
      return permission.name === "Approver" && permission.value === 2;
    });
    const isConsultant = get(sim, "simpatraConsultant", false);
    const isStandardUser = !isSimpatraAdmin && !isOrgAdmin && !isLocationAdmin;

    const isOrgUser = userType === USER_TYPE_CONSTANTS.organizationUser;
    const isPatientUser = userType === USER_TYPE_CONSTANTS.patientUser;
    const isVendorUser = userType === USER_TYPE_CONSTANTS.vendorUser;
    let isSubSuperAdmin = false;
    if (!isEmpty(superAdminPermissionDetails) && superAdminPermissionDetails.superAdminPermissionDetails) {
      superAdminPermissionDetails.superAdminPermissions.forEach(element => {
        isSubSuperAdmin = element.value > 0;
      });
    }

    const permissions = {
      isSimpatraAdmin,
      isConsultant,
      isOrgAdmin,
      isLocationAdmin,
      isStandardUser,
      isOrgOwner,
      isVendorUser,
      isOrgUser,
      isPatientUser,
      isSubSuperAdmin,
      isLocationProfileAdmin,
      isLocationRegistrationAdmin,
      isLocationPaymentAdmin,
      isLocationLicenseAdmin,
      isOrgDirectoryAdmin,
      isOrgApproverAdmin,
      isSchedulingAdmin,
      isPatientAdmin,
    };
    state.currentUserPermissions = permissions;
  }
};

const setCurrentUserInfo = (state, payload) => {
  state.myUserInfo = payload;
};

export default {
  setCurrentLoggedInInfo,
  setCurrentUserInfo,
};
