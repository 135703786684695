const defaultPaginationDetails = {
  currentPage: 0,
  totalPages: 0,
  pageSize: 0,
  totalData: 0,
};

export default {
  defaultPaginationDetails,
  preOrdersCart: {
    data: [],
    paginationDetails: defaultPaginationDetails,
  },
  prescriptionCart: {
    data: null,
    fulfillmentsShippings: [],
    userLicenses: [],
  },
};
