import { merge } from "lodash";
import vendorModuleGetters from "./vendorModule/getters";
import buyerModuleGetters from "./buyerModule/getters";
import ordersModuleGetters from "./ordersModule/getters";
import cartModuleGetters from "./cartModule/getters";

export default merge(
  vendorModuleGetters,
  buyerModuleGetters,
  ordersModuleGetters,
  cartModuleGetters
);
