import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const fetchProductById = async (_, productId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `products/v1/market/product/${productId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  fetchProductById,
};
