import axios from '../lib/axios';

export default {
  namespaced: true,
  state: {
    socialMediaFile: '',
  },

  getters: {},

  mutations: {
    setSocialMediaFile(state, payload) {
      state.socialMediaFile = payload;
    },
  },

  actions: {
    fetchS3BucketFile: async ({ commit }, payload) => {
      const { fileName } = payload;
      const { repository } = payload;
      const response = await axios.get(
        `accounts/v1/outreach/download/${repository}/${fileName}`,
      );

      commit('setSocialMediaFile', response.data);
    },
  },
};
