import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

/**
 * @param {buyerId} param0
 * buyerId = LocationId
 */

const fetchOrderHistory = async ({ commit }, { locationId, page = 0, pageSize = 10, returnResponseOnly = false }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `orders/v1/buyer/${locationId}/purchase/pagenumber/${page}/pagesize/${pageSize}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    if (!returnResponseOnly) {
      commit("setOrderHistory", data);
    }
    return data;
  }
};

const fetchOrderReceipt = async (_, { locationId, purchaseId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `orders/v1/buyer/${locationId}/receipt/${purchaseId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  fetchOrderHistory,
  fetchOrderReceipt,
};
