import { get } from "lodash";

const setOrderHistory = (state, payload) => {
  const data = get(payload, "content", []);
  state.orderHistory.paginationDetails.currentPage = get(payload, "number", 0);
  state.orderHistory.paginationDetails.totalPages = get(payload, "totalPages", 0);
  state.orderHistory.paginationDetails.pageSize = get(payload, "size", 0);
  state.orderHistory.paginationDetails.totalData = get(payload, "totalElements", 0);
  state.orderHistory.data = data;
};

export default {
  setOrderHistory,
};
