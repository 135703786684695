import { get } from "lodash";

const isLoggedIn = (state) => {
  const exp = state.exp;
  const access_token = state.access_token;
  if (exp && access_token) {
    const tokenExpiration = Number(state.exp);
    const isExpired = tokenExpiration < new Date() / 1000;
    return !isExpired;
  }
  return false;
};

const getMyInfo = (state) => {
  return state.currentLoggedInInfo;
};

const getMyUserInfo = (state) => {
  return state.myUserInfo;
};

const getMyPermissions = (state) => {
  return state.currentUserPermissions;
};

const getMyUserId = (state) => {
  const { sim } = state.currentLoggedInInfo;
  return sim.user.subject || null;
};

const getMyFirstName = (state) => {
  return get(state.currentLoggedInInfo.sim.user, "firstName", "");
};

export default {
  isLoggedIn,
  getMyInfo,
  getMyUserId,
  getMyFirstName,
  getMyPermissions,
  getMyUserInfo,
};
