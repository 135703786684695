import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";

const fetchAllOrgVendors = async ({ commit }, { queryParams = {} }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/org/vendors`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    return data;
  }
};

const alterOrgVendors = async ({ commit }, { orgId, updateMode, payload }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `accounts/v1/org/${orgId}/vendors?mode=${updateMode}`;
  const notificationParams = {
    displaySuccessMessage: true,
    successNotificationMsg: updateMode === "ADD" ? "Organization successfully added." : "Organization successfully updated.",
  };
  const response = await proxy.execute(query, payload, notificationParams);
  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchAllOrganizations = async ({ commit }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/org`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchAllVendors = async ({ commit }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/vendor`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  fetchAllOrgVendors,
  alterOrgVendors,
  fetchAllOrganizations,
  fetchAllVendors,
};