import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";

const fetchActiveSubscriptions = async ({ commit }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = "subscription/v1/admin/feature?all=1";
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setOrgActiveFeatures", data);
  }
};

const fetchOrgSubscriptions = async (_, { orgId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `subscription/v1/organization/${orgId}/`;
  const response = await proxy.execute(query);
  let orgSubscription = {};
  if (response) {
    const { data } = response;
    if (data.length > 0) {
      orgSubscription = {
        paymentMethodId: data[0].paymentMethodId,
        nextBillDue: data[0].nextInstallmentDue,
        features: data[0].features,
        planPrice: data[0].planPrice,
        billingPeriod: data[0].billingPeriod,
        billingPeriodType: data[0].billingPeriodType,
        plan: data[0].plan,
      };
    }
  }

  return orgSubscription;
};

const updateOrgSubscription = async (_, { payload, orgId }) => {
  const proxy = new PutSimpatraServiceProxy();
  const query = `subscription/v1/admin/organization/${orgId}/subscription/`;
  await proxy.execute(query, payload, "Subscription Updated Successfuly!");
};

const subscriptionPaymentManualTrigger = async (_, { orgId, subscriptionId }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `subscription/v1/admin/cron/org/${orgId}/subscription/${subscriptionId}/trigger-payment`;
  const response = await proxy.execute(
    query,
    {},
    {
      notificationParams: {
        displaySuccessMessage: true,
        successNotificationMsg: "Succesfully Trigger Manual Subscription Payment!",
      },
    }
  );
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  fetchActiveSubscriptions,
  fetchOrgSubscriptions,
  updateOrgSubscription,
  subscriptionPaymentManualTrigger,
};
