import axios from "@/lib/axios";
import notification from "@/utils/notifications";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";

const processRegistrationRequest = async (_, { orgId, locationId, registrationId, payload }) => {
  try {
    const proxy = new PutSimpatraServiceProxy();
    const query = `accounts/v1/org/${orgId}/location/${locationId}/registration/${registrationId}/process-request`;
    const response = await proxy.execute(query, payload, {
      displaySuccessMessage: true,
      successNotificationMsg: `Registration has been ${payload.status}.`,
    });
    if (response) {
      const { data } = response;
      return data;
    }
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

const fetchVendorRegistrationRequests = async (_, { vendorId, queryParams }) => {
  try {
    const proxy = new GetSimpatraServiceProxy();
    const query = `accounts/v1/vendor/${vendorId}/registration`;
    const response = await proxy.execute(query, queryParams);
    if (response) {
      const { data } = response;
      return data;
    }
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

const processLicenseRenewalRequest = async (_, { orgId, locationId, registrationId, payload }) => {
  try {
    const proxy = new PutSimpatraServiceProxy();
    const query = `accounts/v1/org/${orgId}/location/${locationId}/registration/${registrationId}/renew`;
    const response = await proxy.execute(query, payload, {
      displaySuccessMessage: true,
      successNotificationMsg: `Renewal application successfully applied.`,
    });
    if (response) {
      const { data } = response;
      return data;
    }
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

const fetchOrgLocationRegistrations = async (_, { orgId, locationId, queryParams }) => {
  try {
    const proxy = new GetSimpatraServiceProxy();
    const query = `accounts/v1/org/${orgId}/location/${locationId}/registration`;
    const response = await proxy.execute(query, queryParams);
    if (response) {
      const { data } = response;
      return data;
    }
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

export default {
  processRegistrationRequest,
  fetchVendorRegistrationRequests,
  processLicenseRenewalRequest,
  fetchOrgLocationRegistrations,
};
