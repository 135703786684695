import moment from "moment";

const setPatientDetails = (state, payload) => {
  state.patientDetails = payload;
};

const setAppointments = (state, payload) => {
  state.appointments = payload;
};

const updateAppointmentList = (state, payload) => {
  if (payload.updateProp) {
    const selectedAppointmentIndex = state.appointments.findIndex((appointment) => appointment.id === payload.id);
    state.appointments[selectedAppointmentIndex][payload.prop] = payload.value;
  } else {
    state.appointments.push(payload);
  }
};

const setPatientInfoPayload = (state, payload) => {
  moment.updateLocale("en", {
    relativeTime: {
      yy: "%dy",
    },
  });
  const parsedYrs = parseInt(moment(payload.dob).fromNow().replace(/\D/g, ""));
  state.patientAge = parsedYrs;
  state.patientInfoPayload = payload;
}

const setPatientMergeInfoPayload = (state, payload) => {
  moment.updateLocale("en", {
    relativeTime: {
      yy: "%dy",
    },
  });
  const parsedYrs = parseInt(moment(payload.dob).fromNow().replace(/\D/g, ""));
  state.patientAge = parsedYrs;
  state.patientMergeInfoPayload = payload;
}

export default {
  setPatientDetails,
  setAppointments,
  updateAppointmentList,
  setPatientInfoPayload,
  setPatientMergeInfoPayload,
};
