// THIS IS THE OLD ONBOARDING

export default {
  state: {
    step: 0,
  },

  getters: {
    getStep(state) {
      return state.step;
    },
  },

  mutations: {
    setStep(state, payload) {
      if (state.step++ > 2) {
        state.step = 0;
      }
    },
  },

  actions: {},
};
