// import StateLookups from "@/utils/simpatraRegionSelection";

const setFormData = (state, { type, data }) => {
  state.practiceUserFormData[type] = data;
};

const appendDataToPracticeUserFormData = (state, data) => {
  if (data && data.initialAddress) {
    data.initialAddress.region = data.initialAddress.state;
    data.initialAddress.postalCode = data.initialAddress.zipCode;
    data.initialAddress.name = data.initialAddress.name || "";
    data.initialAddress.phoneNumber = data.initialAddress.phoneNumber || "";
  }
  if (data && data.shippingAddress) {
    data.shippingAddress.region = data.shippingAddress.state;
    data.shippingAddress.postalCode = data.shippingAddress.zipCode;
  }
  state.practiceUserFormData = data;
}

const setVendorInvidationToken = (state, data) => {
  state.vendorInvitationToken = data;
};

const clearState = (state) => {
  state.practiceUserFormData = null
};

// const setPracticeUserFormData = (state, payload) => {
//   state.preLoadedData = payload;
//   const primaryState = StateLookups.find((item) => item.name === state.preLoadedData.primaryState);
//   const { clinicPrimaryAddressPhoneNumber, practitionerPhone, clinicId } = state.preLoadedData;
//   const initialAddress = {
//     name: state.preLoadedData.locationName || "",
//     address1: state.preLoadedData.primaryAddress1,
//     address2: state.preLoadedData.primaryAddress2,
//     city: state.preLoadedData.primaryAddressCity,
//     region: primaryState.abbreviation,
//     postalCode: state.preLoadedData.primaryZip,
//     phoneNumber: clinicPrimaryAddressPhoneNumber || "",
//     primaryClinic: state.preLoadedData.primaryClinic
//   };
//   const shippingAddress = {
//     address1: state.preLoadedData.shippingAddress1,
//     address2: state.preLoadedData.shippingAddress2,
//     city: state.preLoadedData.shippingAddressCity,
//     region: state.preLoadedData.shippingState,
//     postalCode: state.preLoadedData.shippingZip,
//   };
//   const userAccountSetup = {
//     firstName: state.preLoadedData.practitionerFirstName,
//     lastName: state.preLoadedData.practitionerLastName,
//     practitionerDegree: state.preLoadedData.practitionerDegree,
//     phoneNumber: practitionerPhone || "",
//     clinicId
//   }
//   const structuredData = {
//     companyName: state.preLoadedData.clinicName,
//     initialAddress,
//     shippingAddress,
//     userAccountSetup,
//   };
//   state.practiceUserFormData = structuredData;
// };

const setPreloadedData = (state, payload) => {
  state.preLoadedData = payload;
};

export default {
  setFormData,
  setVendorInvidationToken,
  clearState,
  // setPracticeUserFormData,
  setPreloadedData,
  appendDataToPracticeUserFormData,
};
