import store from "@/store";
import jwtdecode from "jwt-decode";
import { getFromCookie } from "@/utils/cryptoUtils";
import { convertBase64ToFile } from "@/utils/file-reader-helper";
import userMe from "@/services/user-me";

const proceedOnboarding = async () => {
  const idMeCode = this.getCodeFromUrl();
  const decodedPayload = getFromCookie("onboardingPayload");
  const base64String = localStorage.getItem("signatureImage");
  const file = convertBase64ToFile(base64String);
  const formData = new FormData();
  formData.append("image", file);
  if (decodedPayload && base64String) {
    decodedPayload.idMeCode = idMeCode || null;
    const response = await store.dispatch("publicAPIModule/clearLocalStore", decodedPayload);
    localStorage.removeItem("finalizeOnboarding");
    if (response && response.data) {
      const decodedToken = initializeLoginDetails(response.data.sessionObject);
      if (response.data.locationId) {
        const uploadResponse = await store.dispatch("filesModule/uploadImgFile", {
          formData,
        });
        if (uploadResponse) {
          const signatureFileLocation = get(uploadResponse, "data", "");
          await store.dispatch("accountsModule/addLocationSignature", {
            locationId: response.data.locationId,
            payload: {
              signatureFileLocation,
            },
          });
        } else {
          // throwSignatureError();
        }
      } else {
        // throwSignatureError();
      }
      await store.dispatch("usersModule/initializeCurrentLoggedInInfo", { access_token: accessToken, exp: decodedToken.exp, decodedToken });
      await store.dispatch("usersModule/navigateBasedOnRoles", { decodedToken });
      return;
    } else if (response.npiError) {
      setTimeout(async () => {
        await store.dispatch("publicAPIModule/logoutUser");
      }, 10000);
      return;
    }
    setTimeout(async () => {
      window.location.href = "/";
    }, 3500);
  }
};


export default {
  proceedOnboarding,
};

