import { get } from "lodash";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import userMe from "@/services/user-me";

const fetch503AProducts = async (_, { queryParams }) => {
  const proxy = new GetSimpatraServiceProxy();
  const currentLocation = get(localStorage, "currentLocation", null);
  const { isSimpatraAdmin } = userMe.getMyRoles();
  const orgId = localStorage.getItem("orgId");
  if (isSimpatraAdmin) {
    queryParams.orgId = orgId;
  }
  const query = `products/v1/location/${currentLocation}/market/pharmacy/search`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    return data;
  }
  return null;
};

export default {
  fetch503AProducts,
};
