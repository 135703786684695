const setFormData = (state, { type, data }) => {
  state.formData[type] = data;
};

const setUserIdentity  = (state, payload) => {
  if (payload.type === 'update') {
    state.userIdentity[payload.fieldName] = payload.fieldValue;
    return;
  }
  state.userIdentity = payload;
};

export default {
  setFormData,
  setUserIdentity,
};
