const getFormData = (state) => state.formData;
const getVendorInvitationToken = (state) => state.vendorInvitationToken;
const getPracticeUserFormData = (state) => state.practiceUserFormData;
const getPreloadedData = (state) => state.preLoadedData;

export default {
  getFormData,
  getVendorInvitationToken,
  getPracticeUserFormData,
  getPreloadedData
};
