import Vue from "vue";
import { notification } from "ant-design-vue";
import axios from "../lib/axios";
import globalUtil from "@/utils/global";

export default {
  state: {
    myProfileStep: false,
    cart: [],
    currentProduct: {},
    allCategories: [],
    checkout: {
      purchase: {},
      availableShippingRates: {
        // fullfillmentId: array of rates
      },
      selectedShippingRates: [
        // { fulfillmentId, shippingRateId, rate(used for subtotal calculation) }
      ],
      selectedRegistrations: [
        // { fulfillmentId, registrationId }
      ],
      selectedPaymentMethodId: "",
      selectedPaymentMetodTypeId: 0,
      receipt: {},
    },
    emptyCart: false,
    recentViewResults: [],
    bestSellerResults: [],
    pageLength: 25,
    page: 1,
    recentViewPage: 1,
    bestSellerPage: 1,
    checkoutStep: false,
    availableShippingRatesObject: {},
    address: {},
  },

  getters: {
    getRecentViewResults: (state) => {
      return state.recentViewResults;
    },
    getMyProfileStep: (state) => {
      return state.myProfileStep;
    },
    getBestSellerResults: (state) => {
      return state.bestSellerResults;
    },
    getEmptyCart: (state) => {
      return state.emptyCart;
    },
    getCheckoutStep: (state) => {
      return state.checkoutStep;
    },
    getAllCategories: (state) => {
      return state.allCategories;
    },
    getCartItems: (state) => {
      return state.cart.sort((a, b) => {
        if (a.vendorName < b.vendorName) {
          return -1;
        }
        if (a.vendorName > b.vendorName) {
          return 1;
        }
        if (a.vendorName === b.vendorName) {
          if (a.productName < b.productName) {
            return -1;
          }
          if (a.productName > b.productName) {
            return 1;
          }
        }
        return 0;
      });
    },
    getCurrentPurchase: (state) => {
      return state.checkout.purchase;
    },
    getAvailableShippingRates: (state) => {
      return state.checkout.availableShippingRates;
    },
    getSelectedShippingRates: (state) => {
      return state.checkout.selectedShippingRates;
    },
    getPurchaseReceipt: (state) => {
      return state.checkout.receipt;
    },
    getProductPageLength(state) {
      return state.pageLength;
    },
    getRecentViewPage(state) {
      return state.recentViewPage;
    },
    getBestSellerPage(state) {
      return state.bestSellerPage;
    },
    getAddressById(state) {
      return state.address;
    },
  },

  mutations: {
    setCurrentProductData(state, product) {
      state.currentProduct = product;
    },
    setCategories(state, data) {
      state.allCategories = data;
    },
    emptyCart(state) {
      state.cart = [];
    },
    setEmptyCart(state, payload) {
      state.emptyCart = payload;
      state.checkout.selectedShippingRates = [];
      state.checkout.purchase = {};
    },
    setAvailableShippingRates(state, payload) {
      const { fulfillmentId, availableRates } = payload;
      /*
      Use Vue.set to handle property addition/deletion:
      https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
      */
      Vue.set(state.checkout.availableShippingRates, fulfillmentId, availableRates);
      state.availableShippingRatesObject = payload.availableRates;
    },
  },

  actions: {
    // Begin Checkout Flow
    setEmptyCart: async ({ commit }, emptyCart) => {
      commit("setEmptyCart", emptyCart);
    },
    fetchAvailableShippingRates: async ({ commit }, body) => {
      try {
        const response = await axios.post("/shipping/v1/shipment", body);
        if (response) {
          const { data } = response;
          commit("setAvailableShippingRates", data);
          return data;
        }
      } catch (error) {
        notification.warning({
          description: error.response.data.error,
        });
        return null;
      }
    },
    fetchProductById: async ({ commit }, productId) => {
      const response = await axios.get(`/products/v1/market/product/${productId}`);
      commit("setCurrentProductData", response.data);
    },
    fetchAllCategories: async ({ commit }) => {
      const response = await axios.get("/products/v1/market/category");
      commit("setCategories", response.data);
      return response.data;
    },
    addItemToCart: async ({ commit, rootGetters, dispatch }, payload) => {
      const isLocationActive = globalUtil.isLocationActive(localStorage.currentLocation);
      await dispatch("accountsModule/fetchOrgDetails", { orgId: rootGetters.getOrgId }, { root: true });
      if (isLocationActive) {
        const { buyerId } = payload;
        const body = {
          productId: payload.productId,
          productPricingId: payload.productPricingId,
          qty: payload.qty,
        };

        const response = await axios.post(`/orders/v1/cart/${buyerId}/item`, body);
        commit("setCartItems", response.data);
      } else {
        throw new Error("Selected location deactivated !");
      }
    },
  },
};
