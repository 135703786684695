import { merge } from "lodash";
import vendorModuleState from "./vendorModule/state";
import buyerModuleState from "./buyerModule/state";
import ordersModuleState from "./ordersModule/state";
import cartModuleState from "./cartModule/state";

export default merge(
  vendorModuleState,
  buyerModuleState,
  ordersModuleState,
  cartModuleState
);
