const setOrgLocationDetails = (state, payload) => {
  state.orgLocationDetails = payload;
};

const setUserLocation = (state, payload) => {
  state.userLocations = payload;
};

const setSelectedCurrentLocation = (state, payload) => {
  state.currentLocation = payload;
};

const setLocationAllPatients = (state, payload) => {
  state.locationAllPatients = payload;
};

const setLocationMedcenterPatients = (state, payload) => {
  state.locationMedcenterPatients = payload;
};

const setLocationIntellipelPatients = (state, payload) => {
  if (typeof payload === "object" && payload.type === "add") {
    state.locationIntellipelPatients.unshift(payload.data);
  } else {
    state.locationIntellipelPatients = payload;
  }
};

const setCurrentLocationDetails = (state, payload) => {
  if (payload.updateProp) {
    state.currentLocationDetails[payload.propName] = payload.value;
  } else {
    state.currentLocationDetails = payload;
  }
};

const setCurrentUserAvailableLocation = (state, payload) => {
  if (payload.dataFilter) {
    state.currentUserAvailableLocation.dataFilter = payload.dataFilter;
  } else {
    state.currentUserAvailableLocation.data = payload;
  }
};

const updateSelectedAvailableLocation = (state, payload) => {
  const locationIndex = state.currentUserAvailableLocation.data.findIndex((location) => location.id === payload.id);
  state.currentUserAvailableLocation.data.splice(locationIndex, 1, payload)
}

const updateAvailableLocations = (state, payload) => {
  if (payload.type === "add") {
    state.currentUserAvailableLocation.data.unshift(payload.data)
  }
}

export default {
  setOrgLocationDetails,
  setUserLocation,
  setSelectedCurrentLocation,
  /**
   * PATIENTS SETTER
   */
  setLocationAllPatients,
  setLocationMedcenterPatients,
  setLocationIntellipelPatients,
  setCurrentLocationDetails,
  setCurrentUserAvailableLocation,
  updateSelectedAvailableLocation,
  updateAvailableLocations,
};
