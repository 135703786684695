import { merge } from "lodash";
import vendorModuleMutations from "./vendorModule/mutations";
import buyerModuleMutations from "./buyerModule/mutations";
import ordersModuleMutations from "./ordersModule/mutations";
import cartModuleMutations from "./cartModule/mutations";

export default merge(
  vendorModuleMutations,
  buyerModuleMutations,
  ordersModuleMutations,
  cartModuleMutations
);
