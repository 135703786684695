const getAvailableLocations = (state) => {
  return state.availableLocations;
};

const getCurrentLocationSelected = (state) => {
  return state.currentLocation;
};

const getCurrentLocationDetails = (state) => {
  return state.currentLocationDetails;
};

const getLocationIntellipelPatients = (state) => {
  return state.locationIntellipelPatients;
};

const getAllAvailableLocations = (state) => {
  const { data } = state.currentUserAvailableLocation;
  return data;
}

const getCurrentUserAvailableLocations = (state) => {
  const { dataFilter, data } = state.currentUserAvailableLocation;
  if (dataFilter.showAll) {
    return data;
  } else {
    return data.filter((location) => location.isActive);
  }
}

const getCurrentUserAvailableLocationsFilter = (state) => {
  return state.currentUserAvailableLocation.dataFilter;
}

export default {
  getAvailableLocations,
  getCurrentLocationSelected,
  getCurrentLocationDetails,
  /**
   * PATIENTS GETTER
   */
  getLocationIntellipelPatients,
  getCurrentUserAvailableLocations,
  getCurrentUserAvailableLocationsFilter,
  getAllAvailableLocations
};
