import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";

const fetchPurchaseFulfillmentByVendorId = async (_, { purchaseId, vendorId, type }) => {
  const proxy = new GetSimpatraServiceProxy();
  const queryParams = {};
  if (type) {
    queryParams.type = type;
  }
  const query = `orders/v1/purchase/${purchaseId}/vendor/${vendorId}/invoice`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchPurchase = async (_, purchaseId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `orders/v1/purchase/${purchaseId}/invoice`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
  return null;
};

const faxInvoice = async (_, { payload, purchaseId }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `orders/v1/purchase/${purchaseId}/invoice/fax`;
  const response = await proxy.execute(query, payload, {
    notificationParams: {
      displaySuccessMessage: true,
      successNotificationMsg: "Order has been processed successfully!",
    },
  });
  return response;
};

export default {
  fetchPurchaseFulfillmentByVendorId,
  fetchPurchase,
  faxInvoice,
};
