import { isEmpty, get } from "lodash";
import axios from "@/lib/axios";
import notification from "@/utils/notifications";
import userMe from "@/services/user-me";

export default {
  state: {
    userType: "",
    landingPageType: "",
    labTestPageLoaded: false,
    id: "",
    name: "",
    details: {
      id: "",
      activeLocations: [],
    },
    brand: {
      name: "",
      // logo: '',
      logo: "https://simpatra-img.s3.us-west-2.amazonaws.com/product/1611239338153-simpatra-logo.png",
      fav: "",
    },
    dosing: {
      detail: {
        freeDoses: 0,
        maxDoses: 0,
        numDosingUsers: 0,
        billingGracePeriod: 0,
        pricePerDose: 0,
        paymentMethodId: "",
      },
      users: [],
    },
    status: false,
    bhrtPageLoaded: false,
    activeLocations: [],
  },

  getters: {
    isLabTestPageLoaded(state) {
      return state.labTestPageLoaded;
    },
    isBHRTPageLoaded(state) {
      return state.bhrtPageLoaded;
    },
    getBrandDetails(state) {
      return state.brand;
    },
    getErrorStatus: (state) => {
      return state.status;
    },
    getOrgDosingUsers: (state) => {
      return state.dosing.users;
    },
    getOrgDosingDetail: (state) => {
      return state.dosing.detail;
    },
    getOrgId: () => {
      return localStorage.getItem("orgId") || "";
    },
    getLandingPageType: (state) => {
      return state.landingPageType;
    },
    // VERIFY IF WE CAN REMOVE
    getUserType: (state) => {
      return state.userType;
    },
  },

  mutations: {
    setBrandDetails(state, payload) {
      state.brand = payload;
    },
    labtestPageLoaded(state, payload) {
      state.labTestPageLoaded = payload;
    },
    bhrtPageLoaded(state, payload) {
      state.bhrtPageLoaded = payload;
    },
    setUserType(state, payload) {
      state.userType = payload;
    },
  },

  actions: {
    commitUserType: async ({ commit }, payload) => {
      commit("setUserType", payload);
    },
    fetchBrandingDetails: async ({ commit }, payload) => {
      const practiceId = payload.orgId;
      const response = await axios.get(`accounts/v1/org/images/${practiceId}`);
      commit("setBrandDetails", response.data);
    },
    fetchAllOrgs: async ({ commit }) => {
      const response = await axios.get("/accounts/v1/org");
      commit("setAvailableOrgs", response.data);
    },
    updateOrgDetails: async ({ commit }, payload) => {
      const orgId = payload.id;
      const body = {
        name: payload.name,
        phone: payload.phone,
        fax: payload.fax,
        email: payload.email,
        website: payload.website,
        address1: payload.address1,
        address2: payload.address2,
        city: payload.city,
        region: payload.region,
        postalCode: payload.postalCode,
        country: payload.country,
      };
      const response = await axios.put(`/accounts/v1/org/${orgId}`, body);
      commit("accountsModule/setOrgDetails", response.data, { root: true });
    },
    // Add
    addOrgUser: async (_, payload) => {
      const { orgId } = payload;
      const body = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
      };

      try {
        const response = await axios.post(`/accounts/v1/org/${orgId}/user`, body);
        if (response) {
          const { data } = response;
          return data;
        }
      } catch (error) {
        notification("warning", "Failed to save due to error!",  error.response.data.message);
        return null;
      }
    },
  },
};
