const getCartPreOrdersData = (state) => {
  return state.preOrdersCart.data;
};

const getCartPreOrdersPaginationDetails = (state) => {
  return state.preOrdersCart.paginationDetails;
};

const getPrescriptionCart = (state) => {
  const { data, fulfillmentsShippings, userLicenses } = state.prescriptionCart;
  return {
    data,
    fulfillmentsShippings,
    userLicenses,
  };
};

export default {
  getCartPreOrdersData,
  getCartPreOrdersPaginationDetails,
  getPrescriptionCart,
};
